.u-text {
  &__overview {
    font-size: 16px;
    line-height: 24px;
    color: $c-font-pt;
    @include l-space(16px);
    padding: 32px 0;
    border-bottom: solid 1px $c-border-blue;
    @include sp {
      padding: 24px 0;
      font-size: 14px;
      @include l-space(14px);
    }
    &__body {
      @extend .u-text__overview;
      color: $c-font;
      border-bottom: solid 1px $c-border;
    }
  }
  &__label {
    font-size: 16px;
    @include l-space(16px);
    font-weight: 700;
    @include sp {
      font-size: 14px;
      @include l-space(14px);
    }
  }
  &__root {
    font-size: 14px;
    line-height: 14px;
    //font-weight: 700;
    @include l-space(14px, 25);
    color: $c-font-pt;
    position: relative;
    &:nth-child(1) {
      border-bottom: solid 1px #556677;
      transition: border-bottom .2s ease;
      &:hover {
        border-bottom: solid 1px #55667700;
      }
    }
    &::after {
      content: ">";
      font-size: 14px;
      line-height: 20px;
      @include l-space(14px, 25);
      color: $c-font-pt;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -14px;
    }
    &:nth-last-child(1) {
      &::after {
        display: none;
      }
    }
  }
  &__body {
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    @include l-space(16px, 25);
    @include sp {
      font-size: 14px;
      line-height: 30px;
      @include l-space(14px, 25);
      &[data-styles="service"] {
        line-height: 24px;
      }
    }
    &--news {
      @extend .u-text__body;
      @include sp {
        line-height: 24px;
      }
    }
    &--company {
      @extend .u-text__body;
      @include sp {
        line-height: 24px;
      }
    }
    &--service {
      @extend .u-text__body;
      margin-top: 32px;
      @include sp {
        margin-top: 24px;
      }
    }
    &--center {
      @extend .u-text__body;
      text-align: center;
      @include sp {
        text-align: left;
        line-height: 24px;
      }
    }
  }
  &__number {
    font-size: 30px;
    line-height: 35px;
    @include l-space(30px);
    font-weight: 700;
    color: $c-main;
    @include sp {
      font-size: 18px;
      @include l-space(18px);
      margin-right: 8px;
      display: inline;
    }
  }
  &__mv-title {
    font-size: 56px;
    line-height: 80px;
    display: flex;
    overflow: hidden;
    @include l-space(56px, 25);
    @include sp {
      font-size: 8.1vw;
      line-height: 10.5vw;
      letter-spacing: 0em;
    }
  }
  &__mv-sub-title {
    font-size: 24px;
    line-height: 35px;
    @include l-space(24px, 25);
    color: $c-main;
    margin-top: 20px;
    @include sp {
      font-size: 3.95vw;
      line-height: 6.4vw;
      margin-top: 10px;
      letter-spacing: 0.064em;
    }
  }
  &__footer-link {
    font-size: 15px;
    color: $c-font;
    font-weight: 400;
    color: #FFFFFF;
    transition: color $a-transition-time;
    &:hover {
      color: #FFFFFF99;
    }
    @include sp {
      font-size: 14px;
    }
  }
  &__copy {
    font-size: 14px;
    @include l-space(14px);
    text-align: end;
    color: white;
    @include sp {
      font-size: 12px;
      @include l-space(12px);
      text-align: center;
    }
  }
  &__header-link {
    font-size: 15px;
    font-weight: 700;
    color: $c-font;
    transition: color $a-transition-time ease;
    &:hover {
      color: $c-main;
    }
    @include sp {
      color: white;
    }
    @include l-space(15px);
    &--contact {
      font-size: 14px;
      font-weight: 700;
      @include l-space(14px);
      color: white;
      background: $c-button;
      width: 140px;
      height: 40px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background $a-transition-time ease;
      &::before {
        @include pc {
          content: "";
          display: block;
          width: 16px;
          height: 12px;
          background-image: url(../img/icon_mail.svg);
          margin-right: 5px;
        }
      }
      &:hover {
        background: $c-main;
      }
      @include sp {
        width: fit-content;
        height: unset;
        background: unset;
        color: white;
      }
    }
  }
  &__category-title {
    font-size: 32px;
    line-height: 28px;
    @include l-space(32px, 25);
    @include sp {
      font-size: 20px;
      @include l-space(20px, 25);
    }
    &--min {
      font-size: 22px;
      line-height: 32px;
      @include l-space(22px);
    }
    &--white {
      @extend .u-text__category-title;
      color: white;
    }
  }
  &__single-title {
    font-size: 44px;
    line-height: 64px;
    @include l-space(44px, 25);
    @include sp {
      font-size: 24px;
      @include l-space(24px, 25);
      line-height: 35px;
    }
    &--white {
      @extend .u-text__single-title;
      color: white;
    }
    &--evidence {
      font-size: 22px;
      line-height: 28px;
      @include l-space(22px);
      color: $c-main;
      @include sp {
        font-size: 16px;
        @include l-space(16px);
        line-height: 28px;
      }
    }
    &--company {
      @extend .u-text__single-title--evidence;
      margin-bottom: 40px;
      @include sp {
        margin-bottom: 24px;
      }
    }
    &--overview {
      @extend .u-text__single-title--evidence;
      margin-bottom: 28px;
      @include sp {
        margin-bottom: 16px;
      }
    }
    &--sub {
      font-size: 28px;
      line-height: 46px;
      @include l-space(28px);
      @include sp {
        font-size: 24px;
        line-height: 40px;
        @include l-space(24px);
        &[data-styles="service"] {
          font-size: 18px;
          line-height: 28px;
          @include l-space(18px);
        }
      }
      &--main-c {
        font-size: 18px;
        line-height: 28px;
        @include l-space(18px);
        color: $c-main;
        @include sp {
          font-size: 16px;
          @include l-space(16px);
          line-height: 24px;
        }
      }
    }
  }
  &__category-sub {
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
    color: $c-font-pt;
    display: block;
    margin-bottom: 20px;
    @include l-space(15px);
    @include sp {
      font-size: 12px;
      margin-bottom: 12px;
      @include l-space(12px);
      &[data-styles="single"] {
        font-size: 14px;
        @include l-space(14px);
      }
    }
    &--min {
      @extend .u-text__category-sub;
      margin-bottom: 8px;
    }
    &--white {
      @extend .u-text__category-sub;
      color: white;
    }
  }

  &__date {
    font-size: 15px;
    color: $c-font-pt;
    @include l-space(15px);
    @include sp {
      font-size: 12px;
      @include l-space(12px);
    }
    &--news {
      font-size: 14px;
      color: $c-font-pt;
      @include l-space(14px);
      align-self: center;
      @include sp {
        font-size: 12px;
        @include l-space(12px);
      }
    }
  }

  &__news-title {
    font-size: 16px;
    @include l-space(16px, 25);
    display: -webkit-box;
    overflow: hidden;
    font-weight: 400;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @include sp {
      font-size: 14px;
      @include l-space(14px, 25);
    }
  }
}
