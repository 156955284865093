@use "sass:math";
// 変数
$c-main: #247D81;
$c-back: #ECF2F5;
$c-font: #1F2833;
$c-font-pt: #556677;
$c-button: #364659;
$c-border: #DAE1E2;
$c-border-blue: #94B7BC;
$c-placeholder: #C3C7CB;

$w-sp: 87.2vw;

$a-transition-time: .2s;


// 関数
@mixin l-space($font-size: 16px, $size: 50) {
  letter-spacing: math.div($font-size, $size);
}

$sp: 1000px;
$pc: 1001px;
@mixin sp {
  @media(max-width: $sp) {
    @content;
  }
}

@mixin pc {
  @media(min-width: $pc) {
    @content;
  }
}

.pc {
  @include sp {
    display: none!important;
  }
}

.sp {
  @include pc {
    display: none!important;
  }
}
