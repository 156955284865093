.p-wrap {
  &__scroll {
    position: absolute;
    width: $w-sp;
    bottom: 132px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1240px;
    margin: 0 auto;
    z-index: 3;
    @include sp {
      bottom: 88px;
    }
  }

  &__contact {
    &__form {
      display: grid;
      margin: 56px auto 0;
      gap: 32px;
      @include sp {
        margin: 40px auto 0;
        gap: 24px;
      }
    }
  }

  &__mv {
    width: $w-sp;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    &[data-styles="top"] {
      max-width: 1240px;
    }
    @include sp {
      &[data-styles="top"] {
        padding: 19.5vw 0 0 0;
        left: -9px;
      }
    }
  }

  &__company {
    display: grid;
    gap: 100px;
    @include sp {
      gap: 80px;
    }
  }

  &__evidence {
    display: grid;
    gap: 80px;
    @include sp {
      gap: 60px;
    }
  }

  &__number {
    display: grid;
    gap: 173px;
    margin-top: 100px;
    @include sp {
      margin-top: 60px;
      gap: 92px;
    }
    &__content {
      padding: 0 0 0 60px;
      border-left: solid 1px $c-main;
      display: grid;
      gap: 80px;
      @include sp {
        padding: 0px;
        border-left: 0px;
        gap: 64px;
      }
      div {
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: -32px;
          width: 100%;
          height: 1px;
          background: $c-border;
        }
        &:nth-last-child(1) {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  &__root {
    width: 100%;
    max-width: 880px;
    margin: 0 auto 80px;
    display: flex;
    gap: 20px;
    @include sp {
      margin: 0 auto 40px;
    }
  }

  &__single {
    width: 100%;
    max-width: 840px;
    margin: 0 auto ;
    &__body {
      display: grid;
      gap: 32px;
      @include sp {
        gap: 24px;
        &[data-styles="evidence"] {
          gap: 16px;
        }
      }
      &--min {
        display: grid;
        gap: 24px;
        @include sp {
          gap: 12px;
        }
      }
    }
  }

  &__category {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto auto;
    gap: 100px;
    @include sp {
      grid-template-columns: 100%;
      display: block;
    }
    &__text {
      display: flex;
      flex-flow: column;
      gap: 40px;
      position: relative;
      @include sp {
        width: $w-sp;
        margin: 0 auto;
      }
    }
  }

  &__service {
    display: grid;
    grid-template-columns: 48.8% 48.8%;
    gap: 24px;
    margin-top: 48px;
    @include sp {
      grid-template-columns: 100%;
      margin-top: 32px;
    }
    &__row {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
    }
    &__title {
      display: grid;
      gap: 120px;
      grid-template-columns: auto auto;
      @include sp {
        display: block;
      }
    }
  }

  &__news {
    display: grid;
    grid-template-columns: 31.2% 31.2% 31.2%;
    gap: 32px;
    margin-top: 52px;
    @include sp {
      grid-template-columns: 100%;
      gap: 16px;
      margin-top: 40px;
    }
    &__row {
      width: $w-sp;
      max-width: 1000px;
      margin: 0 auto;
    }
  }

  &__title-flex {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
}
