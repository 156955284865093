html {
  font-family: 'PT Sans', 'Noto Sans JP', sans-serif;
  background: $c-back;
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], figure, blockquote, dl, dd {
  color: $c-font;
}

input[type="submit"] {
  appearance: none;
}

a {
  text-decoration: none;
}
