.js-scroll-anim {
  opacity: 0;
  transform: translateY(60px);
  transition: all .5s ease;
  &[data-anim="2"] {
    transition-duration: .5s;
  }
  &[data-anim="3"] {
    transition-duration: 1s;
  }
  &[data-anim="4"] {
    transition-duration: 1.5s;
  }
  &[data-anim="5"] {
    transition-duration: 2s;
  }
  &--active {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes pathmove{
  0%{
    height:0;
    top:40px;
    opacity: 0;
    @include sp {
      top: 28px;
    }
  }
  30%{
    height:50px;
    opacity: 1;
    @include sp {
      height: 40px;
    }
  }
  100%{
    height:0;
    top:90px;
    opacity: 0;
    @include sp {
      top: 68px;
    }
  }
}

@keyframes pathmoveSP{
  0%{
    height:0;
    top:28px;
    opacity: 0;
  }
  30%{
    height:40px;
    opacity: 1;
  }
  100%{
    height:0;
    top:68px;
    opacity: 0;
  }
}

@keyframes open {
  from { 
    opacity: 0;
    transform: translateY(-45%);
  }
  to { 
    opacity: 1;
    transform: translateY(-50%);
  }
}

@keyframes open-sp {
  from { 
    opacity: 0;
    transform: translateY(5%);
  }
  to { 
    opacity: 1;
    transform: translateY(0%);
  }
}
