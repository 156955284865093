body {
  @include sp {
    display: block;
  }
}
footer {
  width: 100vw;
  height: 146px;
  background: #1F2833;
  display: flex;
  align-items: center;
  @include sp {
    height: 224px;
  }
}

.l-footer {
  width: $w-sp;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include sp {
    display: grid;
    gap: 32px;
    justify-content: unset;
  }
  &__right {
    display: grid;
    gap: 30px;
    @include sp {
      justify-content: center;
      gap: 32px;
    }
  }
  nav {
    display: flex;
    gap: 40px;
    @include sp {
      display: grid;
      gap: 16px 32px;
      grid-template-columns: max-content max-content max-content;
    }
  }
}
