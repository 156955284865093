.c-img {
  &__scroll {
    width: 28px;
    height: 28px;
    background-image: url(../img/icon_scrolls.svg);
    position: relative;
    cursor: pointer;
    transition: all .2s;
    &:hover {
      opacity: 0.6;
    }
    @include sp {
      background-size: cover;
      width: 20px;
      height: 20px;
    }
    &::after {
      content: "";
      /*描画位置*/
      position: absolute;
      top: 35px;
      left: 50%;
      transform: translateX(-50%);
      /*線の形状*/
      width: 1px;
      height: 50px;
      background: white;
      /*線の動き1.4秒かけて動く。永遠にループ*/
      animation: pathmove 1.4s ease-in-out infinite;
      opacity:0;
      @include sp {
        top: 23px;
        height: 40px;
        animation: pathmoveSP 1.4s ease-in-out infinite;
      }
    }
    &__top {
      width: 52px;
      height: 52px;
      box-shadow: 3px 3px 10px #0000001F;
      border-radius: 26px;
      background-image: url(../img/icon_uparrow.svg);
      background-size: cover;
      background-position: center;
      position: fixed;
      right: 40px;
      bottom:30px;
      opacity: 0;
      transition: $a-transition-time ease;
      cursor: pointer;
      @include pc {
        &:hover {
          bottom: 45px;
        }
      }
      
      @include sp {
        right: 20px;
      }
      &--active {
        opacity: 1;
        bottom: 40px;
      }
      &--bottom {
        @include pc {
          margin-bottom: 146px;
        }
      }
    }
  }
  &__logo {
    display: block;
    width: 166px;
    height: 26px;
    &--white {
      display: block;
      width: 166px;
      height: 26px;
      @include sp {
        width: 128px;
        height: 20px;
        margin: 0 auto;
      }
      &--menu {
        @extend .c-img__logo--white;
        position: absolute;
        top: 20px;
        left: 24px;
      }
    }
    @include sp {
      width: 128px;
      height: 20px;
    }
  }

  &__evidence {
    width: 100%;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    &[data-src="1"] {
      @extend .c-img__evidence;
      background-image: url(../img/graph_01.jpg);
      height: 25.347vw;
      max-height: 365px;
      @include sp {
        height: 70.93vw;
        background-image: url(../img/graph_01_sp.jpg);
      }
    }
    &[data-src="2"] {
      @extend .c-img__evidence;
      background-image: url(../img/graph_02.jpg);
      height: 25.07vw;
      max-height: 361px;
      @include sp {
        height: 70.13vw;
        background-image: url(../img/graph_02_sp.jpg);
      }
    }
    &__wrap {
      padding: 60px;
      background: white;
      @include sp {
        padding: 24px;
      }
    }
  }

  &__mv {
    width: 656px;
    height: 530px;
    display: block;
    background-image: url(../img/fv_pc.png);
    background-size: cover;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 65px;
    z-index: -1;
    &[data-styles="top"] {
      animation: open .9s both ease-out;
      animation-delay: .8s;

      @include sp {
        background-image: url(../img/img_fv_sp.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: 100vw;
        height: 132vw;
        transform: unset;
        top: 21px;
        left: 0px;
        margin-top: 0px;
        z-index: 1;
        animation: open-sp .9s both ease-out;
        animation-delay: .8s;
      }
    }
    &__single {
      height: 400px;
      display: block;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      margin-top: -2px;
      margin-right: 80px;
      animation: open .9s both ease-out;
      animation-delay: .8s;
      @include sp {
        height: 195px;
        transform: unset;
        top: 98px;
        left: 86px;
        animation: open-sp .9s both ease-out;
        animation-delay: .8s;
      }
      &[data-src="service"] {
        width: 443px;
        background-image: url(../img/img_service.png);
        @include sp {
          width: 217px;
        }
      }
      &[data-src="evidence"] {
        width: 491px;
        background-image: url(../img/img_evidence.png);
      }
      &[data-src="company"] {
        width: 438px;
        background-image: url(../img/img_company.png);
      }
    }
  }

  &__about {
    width: 420px;
    height: 520px;
    display: block;
    background-image: url(../img/img_about.png);
    background-size: contain;
    background-repeat: no-repeat;
    @include sp {
      width: 207px;
      height: 256px;
      margin: 0 auto;
      position: relative;
      &::before {
        content: "";
        display: block;
        background-image: url(../img/img_about.png);
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
      &::after {
        content: "";
        display: block;
        width: calc(100vw - 24px);
        height: 200px;
        position: absolute;
        background: $c-main;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        left: 50%;
        margin-left: -12px;
      }
    }
    &__sign {
      width: 100%;
      height: 22.689%;
      display: block;
      background-image: url(../img/about_message.svg);
      background-size: contain;
      background-repeat: no-repeat;
      @include sp {
        height: 19.733vw;
        max-width: 327px;
        max-height: 74px;
      }
    }
  }

  &__service {
    width: 248px;
    height: 224px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 23px;
    position: relative;
    bottom: 0px;
    transition: $a-transition-time ease;
    @include sp {
      width: 248px;
      height: 224px;
      margin-bottom: 25px;
    }
    &[data-src="our-service"] {
      background-image: url(../img/img_service.png);
    }
    &[data-src="evidence"] {
      width: 275px;
      background-image: url(../img/img_evidence.png);
      @include sp {
        width: 275px;
      }
    }
  }
}
