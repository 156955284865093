.c-input {
  width: 100%;
  border-radius: 5px;
  padding: 16px 24px;
  font-size: 16px;
  height: 60px;
  margin-top: 16px;
  @include l-space(16px);
  @include sp {
    font-size: 14px;
    height: 48px;
    @include l-space(14px);
  }
  &::placeholder {
    color: $c-placeholder;
  }
  &__textarea {
    @extend .c-input;
    height: 200px;
    @include sp {
      height: 200px;
    }
  }
}
