.c-button {
  &__hum {
    width: 24px;
    height: 20px;
    background-image: url(../img/icon_hamburger_white.svg);
    background-size: cover;
    background-repeat: no-repeat;
    &--close {
      width: 17px;
      height: 17px;
      background-image: url(../img/icon_close.svg);
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &__wrap {
      width: 60px;
      height: 60px;
      background: #364659;
      display: flex;
      align-items: center;
      justify-content: center;
      &--close {
        width: 60px;
        height: 60px;
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
  }
  &__circle {
    width: 202px;
    height: 60px;
    box-shadow: 0px 3px 10px #0000001F;
    border-radius: 130px;
    background: white;
    display: flex;
    align-items: center;
    padding: 0 32px;
    font-size: 15px;
    color: $c-font;
    font-weight: 700;
    position: relative;
    transition: $a-transition-time ease;
    @include l-space(15px);
    @include sp {
      height: 48px;
      width: 164px;
      padding: 0 24px;
      font-size: 14px;
      @include l-space(14px);
      &[data-styles="about"] {
        margin-top: -8px;
      }
    }
    &:hover {
      background: $c-main;
      box-shadow: 0px 0px 0px #0000001F;
      color: white;
      &::before {
        background-image: url(../img/icon_arrow_white.svg);
      }
    }
    &::before {
      content: "";
      display: block;
      background-image: url(../img/icon_arrow_green.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 14px;
      height: 6px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 32px;
      transition: $a-transition-time ease;
      @include sp {
        right: 24px;
      }
    }
    &--main {
      @extend .c-button__circle;
      background: #28999F;
      color: white;
      &:hover {
        background: #28999F;
        &::before {
          right: 24px;
        }
      }
      &::before {
        background-image: url(../img/icon_arrow_white.svg);
      }
    }
    &--send {
      cursor: pointer;
      @extend .c-button__circle--main;
      justify-content: center;
      margin: 28px auto 0;
      &:hover {
        @include pc {
          color: $c-main;
          background: white;
        }
      }
      @include sp {
        margin: 16px auto 0;
      }
    }
  }
}
