@charset "UTF-8";
@media (max-width: 1000px) {
  .pc {
    display: none !important;
  }
}

@media (min-width: 1001px) {
  .sp {
    display: none !important;
  }
}

.js-scroll-anim {
  opacity: 0;
  transform: translateY(60px);
  transition: all 0.5s ease;
}
.js-scroll-anim[data-anim="2"] {
  transition-duration: 0.5s;
}
.js-scroll-anim[data-anim="3"] {
  transition-duration: 1s;
}
.js-scroll-anim[data-anim="4"] {
  transition-duration: 1.5s;
}
.js-scroll-anim[data-anim="5"] {
  transition-duration: 2s;
}
.js-scroll-anim--active {
  transform: translateY(0);
  opacity: 1;
}

@keyframes pathmove {
  0% {
    height: 0;
    top: 40px;
    opacity: 0;
    @media (max-width: 1000px) {
      top: 28px;
    }
  }
  30% {
    height: 50px;
    opacity: 1;
    @media (max-width: 1000px) {
      height: 40px;
    }
  }
  100% {
    height: 0;
    top: 90px;
    opacity: 0;
    @media (max-width: 1000px) {
      top: 68px;
    }
  }
}
@keyframes pathmoveSP {
  0% {
    height: 0;
    top: 28px;
    opacity: 0;
  }
  30% {
    height: 40px;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 68px;
    opacity: 0;
  }
}
@keyframes open {
  from {
    opacity: 0;
    transform: translateY(-45%);
  }
  to {
    opacity: 1;
    transform: translateY(-50%);
  }
}
@keyframes open-sp {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
html {
  font-family: "PT Sans", "Noto Sans JP", sans-serif;
  background: #ECF2F5;
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body, h1, h2, h3, h4, p, ul[class], ol[class], figure, blockquote, dl, dd {
  color: #1F2833;
}

input[type=submit] {
  appearance: none;
}

a {
  text-decoration: none;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
html {
  margin: 0px !important;
  width: 100vw;
  overflow-X: hidden;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  /* min-height: 100vh; */
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  text-decoration: none;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
  border: none;
}

/* Blur images when they have no alt attribute */
img:not([alt]) {
  filter: blur(10px);
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@media (max-width: 1000px) {
  body {
    display: block;
  }
}

footer {
  width: 100vw;
  height: 146px;
  background: #1F2833;
  display: flex;
  align-items: center;
}
@media (max-width: 1000px) {
  footer {
    height: 224px;
  }
}

.l-footer {
  width: 87.2vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1000px) {
  .l-footer {
    display: grid;
    gap: 32px;
    justify-content: unset;
  }
}
.l-footer__right {
  display: grid;
  gap: 30px;
}
@media (max-width: 1000px) {
  .l-footer__right {
    justify-content: center;
    gap: 32px;
  }
}
.l-footer nav {
  display: flex;
  gap: 40px;
}
@media (max-width: 1000px) {
  .l-footer nav {
    display: grid;
    gap: 16px 32px;
    grid-template-columns: max-content max-content max-content;
  }
}

header {
  height: 84px;
  width: 100vw;
  display: flex;
  align-items: center;
  padding: 0 60px 0 40px;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  top: 0px;
  background: white;
}
@media (max-width: 1000px) {
  header {
    height: 60px;
    padding: 0 0 0 24px;
  }
}
header .l-header__nav {
  display: flex;
  gap: 40px;
  align-items: center;
}
@media (max-width: 1000px) {
  header .l-header__nav {
    position: fixed;
    top: 0px;
    right: -100vw;
    background-image: url(../img/bk_hamburger.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100vw;
    height: 100vh;
    flex-flow: column;
    align-items: baseline;
    padding: 160px 24px;
    transition: 0.3s;
  }
  header .l-header__nav--open {
    right: 0%;
  }
}

.l-header__position {
  height: 84px;
  width: 100vw;
  display: block;
}
@media (max-width: 1000px) {
  .l-header__position {
    height: 60px;
  }
}

.l-section__mv {
  padding: 140px 0 274px;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #DEEBF2 100%) 0% 0% no-repeat padding-box;
  position: relative;
}
@media (max-width: 1000px) {
  .l-section__mv {
    padding: 0px;
    height: 152vw;
  }
}
.l-section__single {
  padding: 212px 0 0;
  width: 100%;
  height: 540px;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #DEEBF2 100%) 0% 0% no-repeat padding-box;
}
@media (max-width: 1000px) {
  .l-section__single {
    padding: 40px 0 0 0;
    height: 340px;
  }
}
.l-section__single__content {
  width: 100%;
  max-width: 1000px;
  margin: -60px auto 0;
  background: #ECF2F5;
  padding: 40px 0 186px 0;
}
@media (max-width: 1000px) {
  .l-section__single__content {
    margin: 0 auto;
    width: 87.2vw;
    padding: 24px 0 80px;
  }
}
.l-section__single--min {
  height: 397px;
  width: 100%;
  background-image: url(../img/bk_green.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 120px 0 0;
}
@media (max-width: 1000px) {
  .l-section__single--min {
    height: 144px;
    padding: 40px 0 0;
    background-position: center;
  }
}
.l-section__about {
  position: relative;
  padding: 100px 0 0;
}
@media (min-width: 1001px) {
  .l-section__about::before {
    content: "";
    display: block;
    width: 400px;
    height: 540px;
    background: #247D81;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
  }
}
@media (max-width: 1000px) {
  .l-section__about {
    padding: 60px 0 0 0;
  }
}
.l-section__service {
  padding: 100px 0 112px;
  background: #ECF2F5;
  width: 87.2vw;
  margin: 0 auto;
}
@media (max-width: 1000px) {
  .l-section__service {
    padding: 60px 0;
  }
}
.l-section__news {
  padding: 80px 0 100px;
  background-image: url(../img/bk_green.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 1000px) {
  .l-section__news {
    padding: 60px 0;
    background-image: url(../img/bk_hamburger.jpg);
  }
}

.c-button__hum {
  width: 24px;
  height: 20px;
  background-image: url(../img/icon_hamburger_white.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.c-button__hum--close {
  width: 17px;
  height: 17px;
  background-image: url(../img/icon_close.svg);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c-button__hum__wrap {
  width: 60px;
  height: 60px;
  background: #364659;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-button__hum__wrap--close {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0px;
  right: 0px;
}
.c-button__circle, .c-button__circle--main, .c-button__circle--send {
  width: 202px;
  height: 60px;
  box-shadow: 0px 3px 10px #0000001F;
  border-radius: 130px;
  background: white;
  display: flex;
  align-items: center;
  padding: 0 32px;
  font-size: 15px;
  color: #1F2833;
  font-weight: 700;
  position: relative;
  transition: 0.2s ease;
  letter-spacing: 0.3px;
}
@media (max-width: 1000px) {
  .c-button__circle, .c-button__circle--main, .c-button__circle--send {
    height: 48px;
    width: 164px;
    padding: 0 24px;
    font-size: 14px;
    letter-spacing: 0.28px;
  }
  .c-button__circle[data-styles=about], [data-styles=about].c-button__circle--main, [data-styles=about].c-button__circle--send {
    margin-top: -8px;
  }
}
.c-button__circle:hover, .c-button__circle--main:hover, .c-button__circle--send:hover {
  background: #247D81;
  box-shadow: 0px 0px 0px #0000001F;
  color: white;
}
.c-button__circle:hover::before, .c-button__circle--main:hover::before, .c-button__circle--send:hover::before {
  background-image: url(../img/icon_arrow_white.svg);
}
.c-button__circle::before, .c-button__circle--main::before, .c-button__circle--send::before {
  content: "";
  display: block;
  background-image: url(../img/icon_arrow_green.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 32px;
  transition: 0.2s ease;
}
@media (max-width: 1000px) {
  .c-button__circle::before, .c-button__circle--main::before, .c-button__circle--send::before {
    right: 24px;
  }
}
.c-button__circle--main, .c-button__circle--send {
  background: #28999F;
  color: white;
}
.c-button__circle--main:hover, .c-button__circle--send:hover {
  background: #28999F;
}
.c-button__circle--main:hover::before, .c-button__circle--send:hover::before {
  right: 24px;
}
.c-button__circle--main::before, .c-button__circle--send::before {
  background-image: url(../img/icon_arrow_white.svg);
}
.c-button__circle--send {
  cursor: pointer;
  justify-content: center;
  margin: 28px auto 0;
}
@media (min-width: 1001px) {
  .c-button__circle--send:hover {
    color: #247D81;
    background: white;
  }
}
@media (max-width: 1000px) {
  .c-button__circle--send {
    margin: 16px auto 0;
  }
}

.c-img__scroll {
  width: 28px;
  height: 28px;
  background-image: url(../img/icon_scrolls.svg);
  position: relative;
  cursor: pointer;
  transition: all 0.2s;
}
.c-img__scroll:hover {
  opacity: 0.6;
}
@media (max-width: 1000px) {
  .c-img__scroll {
    background-size: cover;
    width: 20px;
    height: 20px;
  }
}
.c-img__scroll::after {
  content: "";
  /*描画位置*/
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translateX(-50%);
  /*線の形状*/
  width: 1px;
  height: 50px;
  background: white;
  /*線の動き1.4秒かけて動く。永遠にループ*/
  animation: pathmove 1.4s ease-in-out infinite;
  opacity: 0;
}
@media (max-width: 1000px) {
  .c-img__scroll::after {
    top: 23px;
    height: 40px;
    animation: pathmoveSP 1.4s ease-in-out infinite;
  }
}
.c-img__scroll__top {
  width: 52px;
  height: 52px;
  box-shadow: 3px 3px 10px #0000001F;
  border-radius: 26px;
  background-image: url(../img/icon_uparrow.svg);
  background-size: cover;
  background-position: center;
  position: fixed;
  right: 40px;
  bottom: 30px;
  opacity: 0;
  transition: 0.2s ease;
  cursor: pointer;
}
@media (min-width: 1001px) {
  .c-img__scroll__top:hover {
    bottom: 45px;
  }
}
@media (max-width: 1000px) {
  .c-img__scroll__top {
    right: 20px;
  }
}
.c-img__scroll__top--active {
  opacity: 1;
  bottom: 40px;
}
@media (min-width: 1001px) {
  .c-img__scroll__top--bottom {
    margin-bottom: 146px;
  }
}
.c-img__logo {
  display: block;
  width: 166px;
  height: 26px;
}
.c-img__logo--white, .c-img__logo--white--menu {
  display: block;
  width: 166px;
  height: 26px;
}
@media (max-width: 1000px) {
  .c-img__logo--white, .c-img__logo--white--menu {
    width: 128px;
    height: 20px;
    margin: 0 auto;
  }
}
.c-img__logo--white--menu {
  position: absolute;
  top: 20px;
  left: 24px;
}
@media (max-width: 1000px) {
  .c-img__logo {
    width: 128px;
    height: 20px;
  }
}
.c-img__evidence, .c-img__evidence[data-src="2"], .c-img__evidence[data-src="1"] {
  width: 100%;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
.c-img__evidence[data-src="1"] {
  background-image: url(../img/graph_01.jpg);
  height: 25.347vw;
  max-height: 365px;
}
@media (max-width: 1000px) {
  .c-img__evidence[data-src="1"] {
    height: 70.93vw;
    background-image: url(../img/graph_01_sp.jpg);
  }
}
.c-img__evidence[data-src="2"] {
  background-image: url(../img/graph_02.jpg);
  height: 25.07vw;
  max-height: 361px;
}
@media (max-width: 1000px) {
  .c-img__evidence[data-src="2"] {
    height: 70.13vw;
    background-image: url(../img/graph_02_sp.jpg);
  }
}
.c-img__evidence__wrap {
  padding: 60px;
  background: white;
}
@media (max-width: 1000px) {
  .c-img__evidence__wrap {
    padding: 24px;
  }
}
.c-img__mv {
  width: 656px;
  height: 530px;
  display: block;
  background-image: url(../img/fv_pc.png);
  background-size: cover;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 65px;
  z-index: -1;
}
.c-img__mv[data-styles=top] {
  animation: open 0.9s both ease-out;
  animation-delay: 0.8s;
}
@media (max-width: 1000px) {
  .c-img__mv[data-styles=top] {
    background-image: url(../img/img_fv_sp.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100vw;
    height: 132vw;
    transform: unset;
    top: 21px;
    left: 0px;
    margin-top: 0px;
    z-index: 1;
    animation: open-sp 0.9s both ease-out;
    animation-delay: 0.8s;
  }
}
.c-img__mv__single {
  height: 400px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  margin-top: -2px;
  margin-right: 80px;
  animation: open 0.9s both ease-out;
  animation-delay: 0.8s;
}
@media (max-width: 1000px) {
  .c-img__mv__single {
    height: 195px;
    transform: unset;
    top: 98px;
    left: 86px;
    animation: open-sp 0.9s both ease-out;
    animation-delay: 0.8s;
  }
}
.c-img__mv__single[data-src=service] {
  width: 443px;
  background-image: url(../img/img_service.png);
}
@media (max-width: 1000px) {
  .c-img__mv__single[data-src=service] {
    width: 217px;
  }
}
.c-img__mv__single[data-src=evidence] {
  width: 491px;
  background-image: url(../img/img_evidence.png);
}
.c-img__mv__single[data-src=company] {
  width: 438px;
  background-image: url(../img/img_company.png);
}
.c-img__about {
  width: 420px;
  height: 520px;
  display: block;
  background-image: url(../img/img_about.png);
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 1000px) {
  .c-img__about {
    width: 207px;
    height: 256px;
    margin: 0 auto;
    position: relative;
  }
  .c-img__about::before {
    content: "";
    display: block;
    background-image: url(../img/img_about.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .c-img__about::after {
    content: "";
    display: block;
    width: calc(100vw - 24px);
    height: 200px;
    position: absolute;
    background: #247D81;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    left: 50%;
    margin-left: -12px;
  }
}
.c-img__about__sign {
  width: 100%;
  height: 22.689%;
  display: block;
  background-image: url(../img/about_message.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 1000px) {
  .c-img__about__sign {
    height: 19.733vw;
    max-width: 327px;
    max-height: 74px;
  }
}
.c-img__service {
  width: 248px;
  height: 224px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto 23px;
  position: relative;
  bottom: 0px;
  transition: 0.2s ease;
}
@media (max-width: 1000px) {
  .c-img__service {
    width: 248px;
    height: 224px;
    margin-bottom: 25px;
  }
}
.c-img__service[data-src=our-service] {
  background-image: url(../img/img_service.png);
}
.c-img__service[data-src=evidence] {
  width: 275px;
  background-image: url(../img/img_evidence.png);
}
@media (max-width: 1000px) {
  .c-img__service[data-src=evidence] {
    width: 275px;
  }
}

.c-input, .c-input__textarea {
  width: 100%;
  border-radius: 5px;
  padding: 16px 24px;
  font-size: 16px;
  height: 60px;
  margin-top: 16px;
  letter-spacing: 0.32px;
}
@media (max-width: 1000px) {
  .c-input, .c-input__textarea {
    font-size: 14px;
    height: 48px;
    letter-spacing: 0.28px;
  }
}
.c-input::placeholder, .c-input__textarea::placeholder {
  color: #C3C7CB;
}
.c-input__textarea {
  height: 200px;
}
@media (max-width: 1000px) {
  .c-input__textarea {
    height: 200px;
  }
}

.p-content__number {
  display: grid;
  grid-template-columns: 35px auto;
  gap: 27px;
  position: relative;
}
@media (max-width: 1000px) {
  .p-content__number {
    grid-template-columns: 100%;
  }
}
.p-content__number::after {
  display: block;
  content: "";
  position: absolute;
  width: 100vw;
  height: 1px;
  background: #DAE1E2;
  bottom: -89px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 1000px) {
  .p-content__number::after {
    bottom: -46px;
  }
}
.p-content__number:nth-last-child(1)::after {
  display: none;
}
.p-content__overview {
  display: grid;
  grid-template-columns: 233px auto;
}
@media (max-width: 1000px) {
  .p-content__overview {
    grid-template-columns: 80px auto;
  }
}
.p-content__service {
  width: 100%;
  height: auto;
  padding: 39px 60px 32px;
  background: white;
  box-shadow: 3px 3px 10px #0000001F;
  border-radius: 5px;
  position: relative;
  transition: 0.2s ease;
}
@media (max-width: 1000px) {
  .p-content__service {
    padding: 39px 24px 24px;
  }
}
.p-content__service:hover {
  box-shadow: 0px 0px 0px #0000001F;
}
.p-content__service:hover .u-text__category-title--min {
  color: #247D81;
}
.p-content__service:hover .c-img__service {
  bottom: -6px;
}
.p-content__service:hover::after {
  right: 52px;
}
.p-content__service::after {
  content: "";
  display: block;
  background-image: url(../img/icon_arrow_green.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 14px;
  height: 6px;
  position: absolute;
  right: 60px;
  bottom: 41px;
  transition: 0.2s ease;
}
.p-content__news {
  width: 100%;
  height: auto;
  padding: 24px 32px 32px;
  background: #FFFFFF;
  border-radius: 5px;
  display: grid;
  gap: 16px;
}
@media (max-width: 1000px) {
  .p-content__news {
    padding: 16px 24px 24px;
    gap: 12px;
  }
}
.p-content__news--single {
  padding: 40px 0;
  border-bottom: 1px solid #DAE1E2;
  display: grid;
  grid-template-columns: 100px auto;
  gap: 70px;
}
@media (max-width: 1000px) {
  .p-content__news--single {
    padding: 24px 0;
    grid-template-columns: 64px auto;
    gap: 20px;
  }
}

.p-wrap__scroll {
  position: absolute;
  width: 87.2vw;
  bottom: 132px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1240px;
  margin: 0 auto;
  z-index: 3;
}
@media (max-width: 1000px) {
  .p-wrap__scroll {
    bottom: 88px;
  }
}
.p-wrap__contact__form {
  display: grid;
  margin: 56px auto 0;
  gap: 32px;
}
@media (max-width: 1000px) {
  .p-wrap__contact__form {
    margin: 40px auto 0;
    gap: 24px;
  }
}
.p-wrap__mv {
  width: 87.2vw;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.p-wrap__mv[data-styles=top] {
  max-width: 1240px;
}
@media (max-width: 1000px) {
  .p-wrap__mv[data-styles=top] {
    padding: 19.5vw 0 0 0;
    left: -9px;
  }
}
.p-wrap__company {
  display: grid;
  gap: 100px;
}
@media (max-width: 1000px) {
  .p-wrap__company {
    gap: 80px;
  }
}
.p-wrap__evidence {
  display: grid;
  gap: 80px;
}
@media (max-width: 1000px) {
  .p-wrap__evidence {
    gap: 60px;
  }
}
.p-wrap__number {
  display: grid;
  gap: 173px;
  margin-top: 100px;
}
@media (max-width: 1000px) {
  .p-wrap__number {
    margin-top: 60px;
    gap: 92px;
  }
}
.p-wrap__number__content {
  padding: 0 0 0 60px;
  border-left: solid 1px #247D81;
  display: grid;
  gap: 80px;
}
@media (max-width: 1000px) {
  .p-wrap__number__content {
    padding: 0px;
    border-left: 0px;
    gap: 64px;
  }
}
.p-wrap__number__content div {
  position: relative;
}
.p-wrap__number__content div::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -32px;
  width: 100%;
  height: 1px;
  background: #DAE1E2;
}
.p-wrap__number__content div:nth-last-child(1)::after {
  display: none;
}
.p-wrap__root {
  width: 100%;
  max-width: 880px;
  margin: 0 auto 80px;
  display: flex;
  gap: 20px;
}
@media (max-width: 1000px) {
  .p-wrap__root {
    margin: 0 auto 40px;
  }
}
.p-wrap__single {
  width: 100%;
  max-width: 840px;
  margin: 0 auto;
}
.p-wrap__single__body {
  display: grid;
  gap: 32px;
}
@media (max-width: 1000px) {
  .p-wrap__single__body {
    gap: 24px;
  }
  .p-wrap__single__body[data-styles=evidence] {
    gap: 16px;
  }
}
.p-wrap__single__body--min {
  display: grid;
  gap: 24px;
}
@media (max-width: 1000px) {
  .p-wrap__single__body--min {
    gap: 12px;
  }
}
.p-wrap__category {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto auto;
  gap: 100px;
}
@media (max-width: 1000px) {
  .p-wrap__category {
    grid-template-columns: 100%;
    display: block;
  }
}
.p-wrap__category__text {
  display: flex;
  flex-flow: column;
  gap: 40px;
  position: relative;
}
@media (max-width: 1000px) {
  .p-wrap__category__text {
    width: 87.2vw;
    margin: 0 auto;
  }
}
.p-wrap__service {
  display: grid;
  grid-template-columns: 48.8% 48.8%;
  gap: 24px;
  margin-top: 48px;
}
@media (max-width: 1000px) {
  .p-wrap__service {
    grid-template-columns: 100%;
    margin-top: 32px;
  }
}
.p-wrap__service__row {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.p-wrap__service__title {
  display: grid;
  gap: 120px;
  grid-template-columns: auto auto;
}
@media (max-width: 1000px) {
  .p-wrap__service__title {
    display: block;
  }
}
.p-wrap__news {
  display: grid;
  grid-template-columns: 31.2% 31.2% 31.2%;
  gap: 32px;
  margin-top: 52px;
}
@media (max-width: 1000px) {
  .p-wrap__news {
    grid-template-columns: 100%;
    gap: 16px;
    margin-top: 40px;
  }
}
.p-wrap__news__row {
  width: 87.2vw;
  max-width: 1000px;
  margin: 0 auto;
}
.p-wrap__title-flex {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.u-text__overview, .u-text__overview__body {
  font-size: 16px;
  line-height: 24px;
  color: #556677;
  letter-spacing: 0.32px;
  padding: 32px 0;
  border-bottom: solid 1px #94B7BC;
}
@media (max-width: 1000px) {
  .u-text__overview, .u-text__overview__body {
    padding: 24px 0;
    font-size: 14px;
    letter-spacing: 0.28px;
  }
}
.u-text__overview__body {
  color: #1F2833;
  border-bottom: solid 1px #DAE1E2;
}
.u-text__label {
  font-size: 16px;
  letter-spacing: 0.32px;
  font-weight: 700;
}
@media (max-width: 1000px) {
  .u-text__label {
    font-size: 14px;
    letter-spacing: 0.28px;
  }
}
.u-text__root {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.56px;
  color: #556677;
  position: relative;
}
.u-text__root:nth-child(1) {
  border-bottom: solid 1px #556677;
  transition: border-bottom 0.2s ease;
}
.u-text__root:nth-child(1):hover {
  border-bottom: solid 1px #55667700;
}
.u-text__root::after {
  content: ">";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.56px;
  color: #556677;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -14px;
}
.u-text__root:nth-last-child(1)::after {
  display: none;
}
.u-text__body, .u-text__body--center, .u-text__body--service, .u-text__body--company, .u-text__body--news {
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 0.64px;
}
@media (max-width: 1000px) {
  .u-text__body, .u-text__body--center, .u-text__body--service, .u-text__body--company, .u-text__body--news {
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.56px;
  }
  .u-text__body[data-styles=service], [data-styles=service].u-text__body--center, [data-styles=service].u-text__body--service, [data-styles=service].u-text__body--company, [data-styles=service].u-text__body--news {
    line-height: 24px;
  }
}
@media (max-width: 1000px) {
  .u-text__body--news {
    line-height: 24px;
  }
}
@media (max-width: 1000px) {
  .u-text__body--company {
    line-height: 24px;
  }
}
.u-text__body--service {
  margin-top: 32px;
}
@media (max-width: 1000px) {
  .u-text__body--service {
    margin-top: 24px;
  }
}
.u-text__body--center {
  text-align: center;
}
@media (max-width: 1000px) {
  .u-text__body--center {
    text-align: left;
    line-height: 24px;
  }
}
.u-text__number {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.6px;
  font-weight: 700;
  color: #247D81;
}
@media (max-width: 1000px) {
  .u-text__number {
    font-size: 18px;
    letter-spacing: 0.36px;
    margin-right: 8px;
    display: inline;
  }
}
.u-text__mv-title {
  font-size: 56px;
  line-height: 80px;
  display: flex;
  overflow: hidden;
  letter-spacing: 2.24px;
}
@media (max-width: 1000px) {
  .u-text__mv-title {
    font-size: 8.1vw;
    line-height: 10.5vw;
    letter-spacing: 0em;
  }
}
.u-text__mv-sub-title {
  font-size: 24px;
  line-height: 35px;
  letter-spacing: 0.96px;
  color: #247D81;
  margin-top: 20px;
}
@media (max-width: 1000px) {
  .u-text__mv-sub-title {
    font-size: 3.95vw;
    line-height: 6.4vw;
    margin-top: 10px;
    letter-spacing: 0.064em;
  }
}
.u-text__footer-link {
  font-size: 15px;
  color: #1F2833;
  font-weight: 400;
  color: #FFFFFF;
  transition: color 0.2s;
}
.u-text__footer-link:hover {
  color: #FFFFFF99;
}
@media (max-width: 1000px) {
  .u-text__footer-link {
    font-size: 14px;
  }
}
.u-text__copy {
  font-size: 14px;
  letter-spacing: 0.28px;
  text-align: end;
  color: white;
}
@media (max-width: 1000px) {
  .u-text__copy {
    font-size: 12px;
    letter-spacing: 0.24px;
    text-align: center;
  }
}
.u-text__header-link {
  font-size: 15px;
  font-weight: 700;
  color: #1F2833;
  transition: color 0.2s ease;
  letter-spacing: 0.3px;
}
.u-text__header-link:hover {
  color: #247D81;
}
@media (max-width: 1000px) {
  .u-text__header-link {
    color: white;
  }
}
.u-text__header-link--contact {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.28px;
  color: white;
  background: #364659;
  width: 140px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease;
}
@media (min-width: 1001px) {
  .u-text__header-link--contact::before {
    content: "";
    display: block;
    width: 16px;
    height: 12px;
    background-image: url(../img/icon_mail.svg);
    margin-right: 5px;
  }
}
.u-text__header-link--contact:hover {
  background: #247D81;
}
@media (max-width: 1000px) {
  .u-text__header-link--contact {
    width: fit-content;
    height: unset;
    background: unset;
    color: white;
  }
}
.u-text__category-title, .u-text__category-title--white {
  font-size: 32px;
  line-height: 28px;
  letter-spacing: 1.28px;
}
@media (max-width: 1000px) {
  .u-text__category-title, .u-text__category-title--white {
    font-size: 20px;
    letter-spacing: 0.8px;
  }
}
.u-text__category-title--min {
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.44px;
}
.u-text__category-title--white {
  color: white;
}
.u-text__single-title, .u-text__single-title--white {
  font-size: 44px;
  line-height: 64px;
  letter-spacing: 1.76px;
}
@media (max-width: 1000px) {
  .u-text__single-title, .u-text__single-title--white {
    font-size: 24px;
    letter-spacing: 0.96px;
    line-height: 35px;
  }
}
.u-text__single-title--white {
  color: white;
}
.u-text__single-title--evidence, .u-text__single-title--overview, .u-text__single-title--company {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.44px;
  color: #247D81;
}
@media (max-width: 1000px) {
  .u-text__single-title--evidence, .u-text__single-title--overview, .u-text__single-title--company {
    font-size: 16px;
    letter-spacing: 0.32px;
    line-height: 28px;
  }
}
.u-text__single-title--company {
  margin-bottom: 40px;
}
@media (max-width: 1000px) {
  .u-text__single-title--company {
    margin-bottom: 24px;
  }
}
.u-text__single-title--overview {
  margin-bottom: 28px;
}
@media (max-width: 1000px) {
  .u-text__single-title--overview {
    margin-bottom: 16px;
  }
}
.u-text__single-title--sub {
  font-size: 28px;
  line-height: 46px;
  letter-spacing: 0.56px;
}
@media (max-width: 1000px) {
  .u-text__single-title--sub {
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 0.48px;
  }
  .u-text__single-title--sub[data-styles=service] {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.36px;
  }
}
.u-text__single-title--sub--main-c {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.36px;
  color: #247D81;
}
@media (max-width: 1000px) {
  .u-text__single-title--sub--main-c {
    font-size: 16px;
    letter-spacing: 0.32px;
    line-height: 24px;
  }
}
.u-text__category-sub, .u-text__category-sub--white, .u-text__category-sub--min {
  font-size: 15px;
  line-height: 17px;
  font-weight: 400;
  color: #556677;
  display: block;
  margin-bottom: 20px;
  letter-spacing: 0.3px;
}
@media (max-width: 1000px) {
  .u-text__category-sub, .u-text__category-sub--white, .u-text__category-sub--min {
    font-size: 12px;
    margin-bottom: 12px;
    letter-spacing: 0.24px;
  }
  .u-text__category-sub[data-styles=single], [data-styles=single].u-text__category-sub--white, [data-styles=single].u-text__category-sub--min {
    font-size: 14px;
    letter-spacing: 0.28px;
  }
}
.u-text__category-sub--min {
  margin-bottom: 8px;
}
.u-text__category-sub--white {
  color: white;
}
.u-text__date {
  font-size: 15px;
  color: #556677;
  letter-spacing: 0.3px;
}
@media (max-width: 1000px) {
  .u-text__date {
    font-size: 12px;
    letter-spacing: 0.24px;
  }
}
.u-text__date--news {
  font-size: 14px;
  color: #556677;
  letter-spacing: 0.28px;
  align-self: center;
}
@media (max-width: 1000px) {
  .u-text__date--news {
    font-size: 12px;
    letter-spacing: 0.24px;
  }
}
.u-text__news-title {
  font-size: 16px;
  letter-spacing: 0.64px;
  display: -webkit-box;
  overflow: hidden;
  font-weight: 400;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (max-width: 1000px) {
  .u-text__news-title {
    font-size: 14px;
    letter-spacing: 0.56px;
  }
}

.js-split {
  display: flex;
  overflow: hidden;
}

.js-split-text {
  transform: translateY(200%);
  transition: all 0.7s cubic-bezier(0.7, 0.24, 0, 0.85);
}
.js-split-text--active {
  transform: translateY(0%);
}