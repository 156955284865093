.p-content {
  &__number {
    display: grid;
    grid-template-columns: 35px auto;
    gap: 27px;
    position: relative;
    @include sp {
      grid-template-columns: 100%;
    }
    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 100vw;
      height: 1px;
      background: $c-border;
      bottom: -89px;
      left: 50%;
      transform: translateX(-50%);
      @include sp {
        bottom: -46px;
      }
    }
    &:nth-last-child(1) {
      &::after {
        display: none;
      }
    }
  }

  &__overview {
    display: grid;
    grid-template-columns: 233px auto;
    @include sp {
      grid-template-columns: 80px auto;
    }
  }

  &__service {
    width: 100%;
    height: auto;
    padding: 39px 60px 32px;
    background: white;
    box-shadow: 3px 3px 10px #0000001F;
    border-radius: 5px;
    position: relative;
    transition: $a-transition-time ease;
    @include sp {
      padding: 39px 24px 24px;
    }
    &:hover {
      box-shadow: 0px 0px 0px #0000001F;
      .u-text__category-title--min {
        color: $c-main;
      }
      .c-img__service {
        bottom: -6px;
      }
      &::after {
        right: 52px;
      }
    }
    &::after {
      content: "";
      display: block;
      background-image: url(../img/icon_arrow_green.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 14px;
      height: 6px;
      position: absolute;
      right: 60px;
      bottom: 41px;
      transition: $a-transition-time ease;
    }
  }

  &__news {
    width: 100%;
    height: auto;
    padding: 24px 32px 32px;
    background: #FFFFFF;
    border-radius: 5px;
    display: grid;
    gap: 16px;
    @include sp {
      padding: 16px 24px 24px;
      gap: 12px;
    }
    &--single {
      padding: 40px 0;
      border-bottom: 1px solid $c-border;
      display: grid;
      grid-template-columns: 100px auto;
      gap: 70px;
      @include sp {
        padding: 24px 0;
        grid-template-columns: 64px auto;
        gap: 20px;
      }
    }
  }
}
