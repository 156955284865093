.js-split {
  display: flex;
  overflow: hidden;
}
.js-split-text {
  transform: translateY(200%);
  transition: all .7s cubic-bezier(0.7, 0.24, 0, 0.85); 
  &--active {
    transform: translateY(0%);
  }
}
