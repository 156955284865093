header {
  height: 84px;
  width: 100vw;
  display: flex;
  align-items: center;
  padding: 0 60px 0 40px;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  top: 0px;
  background: white;
  @include sp {
    height: 60px;
    padding: 0 0 0 24px;
  }
  .l-header__nav {
    display: flex;
    gap: 40px;
    align-items: center;
    @include sp {
      position: fixed;
      top:0px;
      right: -100vw;
      background-image: url(../img/bk_hamburger.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100vw;
      height: 100vh;
      flex-flow: column;
      align-items: baseline;
      padding: 160px 24px;
      transition: .3s;
      &--open {
        right: 0%;
      }
    }
  }
}

.l-header__position {
  height: 84px;
  width: 100vw;
  display: block;
  @include sp {
    height: 60px;
  }
}
