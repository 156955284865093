.l-section {
  &__mv {
    padding: 140px 0 274px;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #DEEBF2 100%) 0% 0% no-repeat padding-box;
    position: relative;
    @include sp {
      padding: 0px;
      height: 152vw;
    }
  }

  &__single {
    padding: 212px 0 0;
    width: 100%;
    height: 540px;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #DEEBF2 100%) 0% 0% no-repeat padding-box;
    @include sp {
      padding: 40px 0 0 0;
      height: 340px;
    }
    &__content {
      width: 100%;
      max-width: 1000px;
      margin: -60px auto 0;
      background: $c-back;
      padding: 40px 0 186px 0;
      @include sp {
        margin: 0 auto;
        width: $w-sp;
        padding: 24px 0 80px;
      }
    }
    &--min {
      height: 397px;
      width: 100%;
      background-image: url(../img/bk_green.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      padding: 120px 0 0;
      @include sp {
        height: 144px;
        padding: 40px 0 0;
        background-position: center;
      }
    }
  }

  &__about {
    position: relative;
    padding: 100px 0 0;
    &::before {
      @include pc {
        content: "";
        display: block;
        width: 400px;
        height: 540px;
        background: $c-main;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;
      }
    }
    @include sp {
      padding: 60px 0 0 0;
    }
  }

  &__service {
    padding: 100px 0 112px;
    background: $c-back;
    width: $w-sp;
    margin: 0 auto;
    @include sp {
      padding: 60px 0;
    }
  }

  &__news {
    padding: 80px 0 100px;
    background-image: url(../img/bk_green.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    @include sp {
      padding: 60px 0;
      background-image: url(../img/bk_hamburger.jpg);
    }
  }
}
